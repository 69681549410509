import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreenHelpComponent } from '../components/screen-help/screen-help';
import { FlexLayoutModule } from '@angular/flex-layout';



@NgModule({
  declarations: [ScreenHelpComponent],
  
  imports: [
    CommonModule,
    FlexLayoutModule
  ],
  exports: [ScreenHelpComponent]

})
export class SharedModule { }
