import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'view-image-modal',
  templateUrl: './view-image-modal.component.html',
  styleUrls: ['./view-image-modal.component.scss'],
})
export class ViewImageModalComponent implements OnInit {

  @Input() item: any;

 

  constructor(public api:ApiService,public viewCtrl: ModalController) { }


  ngOnInit() {
    
  }

}
