import { Injectable, NgModule } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, finalize } from 'rxjs/operators';

//import 'rxjs/add/observable/empty';

//import 'rxjs/add/operator/catch';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { LoadingService } from '../services/loading.service';
import { Router } from '@angular/router';
//import { ErrorService } from '@services/error.service';

@Injectable()
export class HttpsRequestInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService,private loading: LoadingService,private router:Router) { }

    intercept = (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
        // TODO add token
        console.log("INTERCEPT")
        const token = this.auth.token || localStorage.getItem('token')
        const dupReq = token ? req.clone({ headers: req.headers.set('Authorization', `Bearer ${token}`) }) : req.clone();
        this.loading.show();
       
        return next.handle(dupReq).pipe(
            finalize(() => this.loading.hide()),
            catchError(err => {
            if (err.status > 400 && err.status <= 500) {
                console.log("ERR  ")
                this.router.navigateByUrl('login');

                // auto logout if 401 response returned from api
                this.auth.logout();
                //location.reload(true);
            }

            const error = err.error.message || err.statusText;
           // this.errorService.showError("error");
            return throwError(error);
        }))
          
    }
};

@NgModule({
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: HttpsRequestInterceptor, multi: true }
    ]
})
export class InterceptorModule { }
