import { Injectable } from '@angular/core';
import { of, throwError } from 'rxjs';
import {tap, catchError} from 'rxjs/operators'
import { HttpClient } from '@angular/common/http';
import {environment as env, environment} from '../../environments/environment'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient) { }

  token = null;

  login(form){
    console.log("FORM",form)
    return this.http.post(env.baseUrl+"auth/token-auth/",form)
      .pipe(
        tap((res:any)=>{
          if(res && res.token){
            console.log("GOT TOKEN",res);
            
            this.token = res.token

            localStorage.setItem("token",res.token);
          }
        }),
            catchError(err=>
              throwError("Error login"))
        );
  }

  logout(){
    localStorage.removeItem("token");
  }

  registerUser(user:any,password:String): Promise<any>
    {

        return new Promise((resolve, reject) => {
            //var articleCopy = Object.assign({},this.article);
            //delete articleCopy.gallery;
            //delete this.provider.image;
            let newUser = {
                username:user.username,
                password1:password,
                password2:password,
                email:user.email,
            }
            this.http.post(`${environment.baseUrl}/rest-auth/registration/`,newUser)
                .subscribe((response:any) => {
                    console.log("RESPONSE:",response);

                    localStorage.setItem("token",response.key);
                    resolve(response.key);
                },error=>{
                    reject(error);
                    console.log("USER ERROR",error);
                });
        });
    }


}
