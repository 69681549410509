import { Injectable } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {environment as env}  from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { 

  }


  uploadImage(){
    return of(true);
  }


  createPublicEvent(name){
    return this.http.post(env.apiUrl+"public/create",{name:name,config:{}});
  }

  updatePublicEventName(name){
    return this.http.post(env.apiUrl+"public/event?token="+localStorage.getItem("eventToken"),{name:name});

  }

  updatePublicEventBackground(bg){
    return this.http.post(env.apiUrl+"public/event?token="+localStorage.getItem("eventToken"),{"config":{"background":bg}});

  }


  updateConfig(eventId,config){
    
    return this.http.patch(env.apiUrl+"event/"+eventId+"/",{id:eventId,config:config});

  }


  getGalleries(eventId){
    
    return this.http.get(env.apiUrl+"event?token="+eventId)
  }

  getPhotos(event_id:number){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.get(env.apiUrl+"photo/?event="+event_id,{headers:headers}).toPromise();
  }

  getVideos(event_id:number){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.get(env.apiUrl+"video/?event="+event_id,{headers:headers}).toPromise()
  }

  setVideoAccepted(video_id:number,accepted){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.patch(env.apiUrl+`video/${video_id}/`,{accepted:accepted},{headers:headers}).toPromise()
  }

  setPhotoAccepted(photo_id:number,accepted){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.patch(env.apiUrl+`photo/${photo_id}/`,{accepted:accepted},{headers:headers}).toPromise()
  }
  

  getAudios(event_id:number){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.get(env.apiUrl+"audio/?event="+event_id,{headers:headers}).toPromise();
  }


  getPresentations(event_id:number){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.get(env.apiUrl+"presentation/?event="+event_id,{headers:headers}).toPromise();
  }
  


  getEvents(){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.get(env.apiUrl+"event/",{headers:headers});
  }

  getEvent(id){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.get(env.apiUrl+"event/"+id,{headers:headers});
  }

  getGallery(id){
    let token = localStorage.getItem("token");
    let headers = new HttpHeaders().set('Authorization', `Bearer ${token}`); // create header object
    return this.http.get(env.apiUrl+"gallery/"+id,{headers:headers});
  }


  getGalleryByHash(hash){

    let token = localStorage.getItem("token");
    return this.http.get(env.apiUrl+`public/live/${hash}`);
  }

  getEventByToken(){
    let token = localStorage.getItem("eventToken");
    return this.http.get(env.apiUrl+"public/event?token="+token);
  }


  addPresentation(presentation){
    return this.http.post(env.apiUrl+"presentation/",presentation)
  }




  createEvent(event){
    return this.http.post(env.apiUrl+"event/",event);
  }

  updateModerated(event_id,moderated){
    return this.http.patch(env.apiUrl+`event/${event_id}/`,{moderated:moderated});
  }

  updatePresentation(presentation){
    var id = presentation.id;

    return this.http.put(env.apiUrl+`presentation/${id}/`,presentation)
  }

 
  removePresentation(presentation){
    return this.http.delete(env.apiUrl+`presentation/${presentation.id}/`);
  }

  removeImage(item){
    console.log("REMOVE IMAGE:",item);
    return this.http.delete(env.apiUrl+`photo/${item.id}/`);
  }

  removeAudio(item){
    return this.http.delete(env.apiUrl+`audio/${item.id}/`);
  }

  removeVideo(item){
    return this.http.delete(env.apiUrl+`video/${item.id}/`);
  }



}
