import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ApiService } from './services/api.service';

import { HttpClientModule } from '@angular/common/http';
import { InterceptorModule } from './interceptors/interceptor.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AddPresModalComponent } from './components/add-pres-modal/add-pres-modal.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs'; 

import { ScreenHelpComponent } from './components/screen-help/screen-help';
import { SharedModule } from './shared/shared.module';
import { AddEventModalComponent } from './components/add-event-modal/add-event-modal.component';
import { ViewImageModalComponent } from './components/view-image-modal/view-image-modal';
import { LandingComponent } from './landing/landing.component';
import { FlexLayoutModule } from '@angular/flex-layout';

@NgModule({
  declarations: [
    AppComponent,
    AddPresModalComponent,
    AddEventModalComponent,
    LandingComponent,
    ViewImageModalComponent],
  entryComponents: [
    AddPresModalComponent,
    AddEventModalComponent,
    ViewImageModalComponent,

  ],
  imports: [
    CommonModule,
    SharedModule,
    FlexLayoutModule,
    TooltipModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    InterceptorModule,
    FormsModule,

  ],
  providers: [
    StatusBar,
    ApiService,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
