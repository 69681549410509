import { Component, Input, OnInit, AfterViewInit, NgZone, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';

/**
 * Generated class for the ScreenHelpComponent component.
 *
 * See https://angular.io/api/core/Component for more info on Angular
 * Components.
 */


@Component({
  selector: 'screen-help',
  templateUrl: './screen-help.html',
  styleUrls:['./screen-help.scss']
})
export class ScreenHelpComponent implements OnInit ,AfterViewInit,OnDestroy{
  

  text: string;

  @Input('page') page: string;
  @Input('isVisible') isVisible: boolean;
  
  @ViewChild("bubble") bubbleElement:ElementRef
  @ViewChild("arrowTop") arrowTopElement:ElementRef
  @ViewChild("arrowBottom") arrowBottomElement:ElementRef

  helpElement:string
  position:string;
  currentIndex = 0;
  currentMessage ="";

  

  textSide = "right";

  helpers = {
    "menu":[
      {"position":"bottom","help-element":"help-position-f","message":"Filter your venue"},
      {"position":"bottom","help-element":"help-position-d","message":"Filter your venue 2"},

    ],

    "editor":[
      {"position":"bottom","help-element":"drop-resources","message":"Arrastra tus imágenes y audios a este panel para usarlas en tus presentaciones"},
      {"position":"top","help-element":"drop-timeline","message":"Arrastra imágenes desde el panel de recursos, para componer tus presentaciones"},

    ],

    "list":[
      {"position":"bottom","help-element":"second-tab","message":"Send your audios to the server here"},
      {"position":"bottom","help-element":"first-lissdst-item","message":"Long Press an item for multiple select"},
      {"position":"bottom","help-element":"first-list-item","message":"Long Press an item for multiple select"},
    ]
    
  }
  
  
  constructor(private ngZone:NgZone,private changeDetector : ChangeDetectorRef) {
      /*this.pagenav.on('navigatingTo', (data) => {
        console.log("NAVIGATING TO",data);
        // run init code
        // (note: this will run when you either move forward or back to this page)
      })*/
  }

  ngOnDestroy(){
  }


  
  

 

  //CHACK TO CHANGES TO SEE IF HIDE OR SHOW HELP FROM PARENT COMPONENT
 
  lastStyle = null;
  lastElement = null;
  public setPosition(index){
    console.log("SET POSITION HELP",index);
    this.currentIndex = index;

    if(this.isVisible){
      this.ngZone.run(()=>{

        this.helpElement = this.helpers[this.page][index]["help-element"];
        var element = document.getElementById(this.helpElement);

        console.log("ELEMENT:",element);
        
       
        if(this.lastStyle != null){
          this.lastElement.style = Object.assign({},this.lastStyle);
        }else{
          console.log("nO ELEMENT");
        }

        if(!element){
          this.lastStyle = null;
          if(index < this.helpers[this.page].length-1){
            this.setPosition(index+1);
          }
          return;
        }

        this.position = this.helpers[this.page][index]["position"]


        this.currentMessage = this.helpers[this.page][index]["message"]

        
        var rect = element.getBoundingClientRect();

        this.lastStyle =  element.style;
        this.lastElement = element;

        element.style.transition = "all 0.5s ease-in-out";
        element.style.animation = "blink normal 1.5s infinite ease-in-out";

        var pointer = null;

       
       
        var bubble = this.bubbleElement.nativeElement;      
        var widthBubble = window.innerWidth*0.8;

        bubble.style.width = widthBubble+"px"

        




       // setTimeout(()=>{
          console.log("POSITION NEXT",this.position);
          this.changeDetector.detectChanges();


            switch(this.position){
            
              case "bottom":
                
                  bubble.style.textAlign = "center";
                  bubble.style.width = widthBubble+"px"
                  bubble.style.top = (rect.bottom+50)+'px';
                  pointer = this.arrowBottomElement.nativeElement
                  pointer.style.transform = "rotate(200deg)";
                  pointer.style.top = rect.bottom+'px';
                  var widthElement = (rect.right - rect.left)/2;
                  pointer.style.left = rect.left+widthElement+'px';
                  bubble.style.left = rect.left+(widthElement/1)+'px';


                  break;
        
              case "top":

                  bubble.style.width = widthBubble+"px"
                  bubble.style.top = (rect.top-150)+'px';
                  pointer = this.arrowTopElement.nativeElement
                  pointer.style.transform = "rotate(95deg)";
                  pointer.style.top = (rect.top-50)+'px';
                  var widthElement = (rect.right - rect.left)/2;
                  pointer.style.left = rect.left+widthElement+'px';
                  bubble.style.left = rect.left+(widthElement/1)+'px';
                  console.log("RECT LEFT:",rect.left);
                  console.log("RECT RIGHT:",rect.right);

                  console.log("LEFT BUUBLE:",bubble.style.left);
                  console.log("LEFT pointer:",pointer.style.left);

                  break;

            
            }
            console.log("HEY");
          //},0)
       
      })
    }
  }

  closeHelp(){
    if(this.lastStyle != null){
      this.lastElement.style = this.lastStyle;
    }
    this.lastStyle = null;
    this.currentIndex=0;
    this.isVisible = false;
  }

  nextHelp(){
    if(this.currentIndex < this.helpers[this.page].length-1){
      this.currentIndex++;
      this.setPosition(this.currentIndex);
    }else{
      this.setPosition(this.currentIndex);
      this.closeHelp();
    }
  }
  prevHelp(){
    if(this.currentIndex > 0){
      this.currentIndex--;
      this.setPosition(this.currentIndex);
    }else{
      this.currentIndex = this.helpers[this.page].length-1
      this.setPosition(this.currentIndex);

    }
  }
  ngOnInit(){
    console.log("HITS PAGE:",this.page);

    
    
  }

  ngAfterViewInit(){
    this.setPosition(this.currentIndex);

  }

}
