import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-pres-modal',
  templateUrl: './add-pres-modal.component.html',
  styleUrls: ['./add-pres-modal.component.scss'],
})
export class AddPresModalComponent implements OnInit {

  @Input() eventId: number;

  public presentation:any = {
    name:"generic",
    config:{},
    event:null
  }

  constructor(public api:ApiService,public viewCtrl: ModalController) { }

  createPresentation(){

    this.api.addPresentation(this.presentation).subscribe(res=>{
      console.log("PRESENTATION CREATED:",res);
      this.viewCtrl.dismiss(res);
    },err=>{
      this.viewCtrl.dismiss(err);
    })
  }
  ngOnInit() {
    console.log("GALLERY ID",this.eventId);
    this.presentation.event = this.eventId
  }

}
