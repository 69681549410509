import { Component, OnInit, Input } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-add-event-modal',
  templateUrl: './add-event-modal.component.html',
  styleUrls: ['./add-event-modal.component.scss'],
})
export class AddEventModalComponent implements OnInit {


  public event:any = {
    name:"generic",
    config:{},
    moderated:true,
    start:new Date(),
    
  }

  constructor(public api:ApiService,public viewCtrl: ModalController) { }

  createEvent(){

    this.api.createEvent(this.event).subscribe(res=>{
      console.log("PRESENTATION CREATED:",res);
      this.viewCtrl.dismiss(res);
    },err=>{
      this.viewCtrl.dismiss(err);
    })
  }
  ngOnInit() {
    
  }

}
