import { Injectable, Component, EventEmitter, Output } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
}) 
export class LoadingService {

    @Output() loadingEmissor = new EventEmitter();
    debouncer: Subject<Boolean> = new Subject<Boolean>();

    constructor() {
        this.debouncer.pipe(debounceTime(1000)).subscribe(
            (value) => this.loadingEmissor.emit(value)
        )
        
    }

    show()
    {
        this.loadingEmissor.next(true);
    }

    hide()
    {
        this.debouncer.next(false);

    }
}
